import { LocalProperties } from 'src/components/GraphicDetails/utils';
import { toDatetimeLocal } from 'src/utils';
import { getValues, getRepeaterItems } from './utils';

import { Form, FormGroup, Input } from '@southfields-digital/mpxlive-components';
import Select from 'src/components/Select';
import TimerInput from '../InputTimer';
import Repeater, { Id, Items } from 'src/components/Repeater';

import styles from './GraphicPropertiesForm.module.scss';

interface IGraphicPropertiesFormProps {
  properties: LocalProperties | null;
  handleChangeGraphicProperties: (properties: LocalProperties) => void;
  handleSelectMedia: (
    properties: ImageProperties,
    handleSelectValue?: (value: string) => void
  ) => void;
}

export default function GraphicPropertiesForm({
  handleSelectMedia,
  properties,
  handleChangeGraphicProperties,
}: IGraphicPropertiesFormProps) {
  const updatePlaylistGraphicProperties = (id: string, props: Properties, value: any) => {
    handleChangeGraphicProperties?.({
      ...properties,
      [id]: { ...props, value },
    });
  };

  const handleChangeInput = (e: React.SyntheticEvent<HTMLInputElement>, props: Properties) => {
    let value = e.currentTarget.value;
    if (e.currentTarget.type === 'datetime-local') {
      value =
        typeof value === 'string' && !isNaN(Date.parse(value)) ? new Date(value).toISOString() : '';
    }
    updatePlaylistGraphicProperties(props.id, props, value);
  };

  const handleClickMedia = (
    props: ImageProperties,
    handleSelectValue?: (value: string) => void
  ) => {
    handleSelectMedia(props, handleSelectValue);
  };

  const handleChangeRepeater = (_id: Id, _rowIndex: number, rows: Items[], props: Properties) => {
    const values = getValues(rows);
    updatePlaylistGraphicProperties(props.id, props, values);
  };

  const handleChangeSelect = (value: string, props: Properties) => {
    updatePlaylistGraphicProperties(props.id, props, value);
  };

  const handleChangeTimer = (time: number, props: Properties) => {
    updatePlaylistGraphicProperties(props.id, props, time);
  };

  if (!properties) return null;
  return (
    <div className={styles.Container}>
      <Form className={styles.Form} onSubmit={() => {}} key={'form'}>
        {Object.keys(properties).map((property) => {
          const props = properties[property];
          const { id, name, type, value } = props;
          switch (type) {
            case 'text':
              const { placeholder } = props;
              return (
                <Input
                  key={id}
                  id={id}
                  name={name}
                  label={name}
                  value={value}
                  placeholder={placeholder}
                  onChange={(e: React.SyntheticEvent<HTMLInputElement>) =>
                    handleChangeInput(e, { ...properties[property] })
                  }
                  classNameWrapper={styles.ElementWrapper}
                />
              );
            case 'image':
              return (
                <Input
                  key={id}
                  type="selectableInput"
                  id={id}
                  label={name}
                  name={name}
                  value={value}
                  placeholder="Click to select image"
                  onClick={() => handleClickMedia(props)}
                  classNameWrapper={styles.ElementWrapper}
                  className={styles.SelectableInput}
                />
              );
            case 'repeater':
              return (
                <FormGroup label={name} key={id} className={styles.RepeaterWrapper}>
                  <Repeater
                    key={`repeater-${id}`}
                    id={id}
                    items={getRepeaterItems(props, value)}
                    onChangeRows={(id, rowIndex, rows) =>
                      handleChangeRepeater(id, rowIndex, rows, props)
                    }
                    handleSelectMedia={handleClickMedia}
                  />
                </FormGroup>
              );
            case 'select':
              const { options } = props;
              return (
                <FormGroup label={name} key={id} htmlFor={id} className={styles.ElementWrapper}>
                  <Select
                    id={id}
                    options={options}
                    value={value ?? ''}
                    onChange={(e) => handleChangeSelect(e.currentTarget.value, props)}
                  />
                </FormGroup>
              );
            case 'countdown':
              const inputValue = value ? toDatetimeLocal(new Date(value)).localDateTime : '';
              return (
                <Input
                  key={id}
                  type="datetime-local"
                  id={id}
                  name={name}
                  label={name}
                  value={inputValue}
                  onChange={(e: React.SyntheticEvent<HTMLInputElement>) =>
                    handleChangeInput(e, { ...properties[property] })
                  }
                  classNameWrapper={styles.ElementWrapper}
                />
              );
            case 'timer':
              return (
                <TimerInput
                  value={value}
                  id={id}
                  name={name}
                  key={id}
                  onChange={(value: number) =>
                    handleChangeTimer(value, {
                      ...properties[property],
                    })
                  }
                />
              );
            case 'clock':
            default:
              return null;
          }
        })}
      </Form>
    </div>
  );
}
