import { useContext, useState, useMemo, createContext } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { REQUEST_USER_LOGOUT, UserAuthType } from '../redux/reducers/user';
import { routeMap } from '../config/routes';

import {
  Navbar,
  Sidebar,
  SidebarMenuItem,
  SidebarMenuItems,
} from '@southfields-digital/mpxlive-components';
import { IconNames } from '@southfields-digital/mpxlive-components/build/components/Icon';
import GoLive from 'src/components/GoLive';
import DateInput from 'src/components/DateInput';
import LiveClock from 'src/components/LiveClock';
import LiveRundowns from 'src/components/LiveRundowns';
import RundownHeader from 'src/components/RundownHeader/RundownHeader';
import RundownSettings from 'src/components/RundownSettings';
import styles from './BaseLayout.module.scss';
import type { BaseLayoutContextType } from './BaseLayout.types';
import { StateType } from 'src/redux/reducers';
import { REQUEST_GET_LIVE_RUNDOWNS } from 'src/redux/reducers/rundown';

export const BaseLayoutContext = createContext<BaseLayoutContextType | null>(null);

const Container = ({
  children,
  withContainer,
}: {
  children: React.ReactNode;
  withContainer: boolean;
}) => {
  return !withContainer ? (
    <>{children}</>
  ) : (
    <div className={styles.Container}>
      <div className={styles.ContainerInner}>{children}</div>
    </div>
  );
};

export default function BaseLayout({
  auth,
  children,
}: {
  auth?: UserAuthType;
  children?: React.ReactNode;
}) {
  const [currentlyOpenRundownId, setCurrentlyOpenRundownId] = useState<string | undefined>(
    undefined
  );
  const [fullWidth, setFullWidth] = useState(false);
  const [withContainer, setWithContainer] = useState(false);
  const [displayClock, setDisplayClock] = useState(false);
  const [goLive, setGoLive] = useState(false);
  const [showDateinput, setShowDateInput] = useState(false);
  const [showRundownName, setShowRundownName] = useState(false);
  const [showRundowSettings, setShowRundownSettings] = useState(false);

  const logoutLabel = useMemo(
    () => auth && (auth.workspace?.name ? `${auth.name} (${auth.workspace.name})` : auth.name),
    [auth]
  );

  const dispatch = useDispatch();
  const liveRundowns = useSelector((state: StateType) => state.rundown.live);

  const menuItems = routeMap
    ?.filter(({ position }) => position === 'sidebar')
    ?.map(
      (menuItem): SidebarMenuItem => ({
        icon: menuItem.icon as IconNames,
        pathname: menuItem.path as string,
      })
    );

  return (
    <div className={styles.Root}>
      <BaseLayoutContext.Provider
        value={{
          setCurrentlyOpenRundownId,
          setFullWidth,
          setWithContainer,
          setDisplayClock,
          setGoLive,
          setShowDateInput,
          setShowRundownName,
          setShowRundownSettings,
        }}
      >
        <Navbar
          isAuthenticated
          className={styles.Navbar}
          contentCenter={<div>{showRundownName && <RundownHeader />}</div>}
          contentRight={
            <div className="flex items-center gap-x-3">
              {goLive && <GoLive />}
              {displayClock && <LiveClock />}
              {showDateinput && <DateInput />}
              {showRundowSettings && <RundownSettings />}

              <LiveRundowns
                currentlyOpenRundownId={currentlyOpenRundownId}
                liveRundowns={liveRundowns}
                getLiveRundowns={() => dispatch({ type: REQUEST_GET_LIVE_RUNDOWNS })}
              />
            </div>
          }
        />

        <main className={styles.Main}>
          <Sidebar
            menuItems={menuItems as SidebarMenuItems}
            className={styles.Sidebar}
            isAuthenticated
            logoutLabel={logoutLabel}
            handleLogout={() => dispatch({ type: REQUEST_USER_LOGOUT })}
          />

          <div
            className={classNames(styles.View, {
              [styles.FullWidthView]: fullWidth,
              [styles.ContainedView]: withContainer,
            })}
          >
            <Container withContainer={withContainer}>{children || <Outlet />}</Container>
          </div>
        </main>
      </BaseLayoutContext.Provider>
    </div>
  );
}

export const injectBaseLayoutContext = (WrappedComponent: React.FC<any>) => {
  const WithBaseLayoutContext = (props: any) => {
    const baseLayoutContext = useContext<BaseLayoutContextType | null>(
      BaseLayoutContext
    ) as BaseLayoutContextType;

    return <WrappedComponent baseLayoutContext={baseLayoutContext} {...props} />;
  };

  return WithBaseLayoutContext;
};

export * from './BaseLayout.types';
