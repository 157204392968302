import { Contestant, Match } from 'src/types/statsperform/fixtures';
import { Ranking } from 'src/types/statsperform/standings';
import { Competition } from 'src/types/statsperform/tournamentCalendar';

const ALLOW_COMPETITION_CODES: string[] = ['UCL', 'UCF', 'UEL'];

const getCategory = (graphicRef: string, selectedProperty?: Properties) => {
  if (!selectedProperty) return null;
  if (
    selectedProperty.type === 'repeater' &&
    (graphicRef === 'LeagueTableFull' ||
      graphicRef === 'LeagueTableHalf' ||
      graphicRef === 'LowerThirdStats' ||
      graphicRef === 'SideBarStandings' ||
      graphicRef === 'LeagueTable')
  ) {
    return 'Standings';
  } else if (
    selectedProperty.type === 'repeater' &&
    (graphicRef === 'LBarResults' || graphicRef === 'LBarSchedule')
  ) {
    return 'Fixtures';
  } else if (selectedProperty.type === 'text') {
    return 'Statistics';
  }
  return null;
};

const getPropertyOptionsSportData = (graphicName: string, properties: Properties[]) => [
  { label: 'Select a form field to import data', value: '' },
  ...properties
    .filter((property) => getCategory(graphicName, property) !== null)
    .map((property) => ({
      label: property.name,
      value: property.id,
    })),
];

const getDomesticCompetitions = (competitions?: Competition[]) => {
  if (!competitions) return [];
  return [
    { label: 'Select a domestic league', value: '' },
    ...competitions
      .filter(
        (competition) =>
          competition.competitionFormat === 'Domestic league' ||
          ALLOW_COMPETITION_CODES.includes(competition.competitionCode)
      )
      .map((competition) => ({
        label: competition.name,
        value: competition.tournamentCalendar[0].id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  ];
};

const getPropertyValueLeagueTable = (
  data: Ranking[],
  translations: DataProviderTranslation[]
): PropertyValue[] =>
  data.map((team) => ({
    teamRank: team.rank.toString(),
    teamName: getTranslation<Ranking>(translations, team, 'contestantId', 'contestantName'),
    teamGames: team.matchesPlayed?.toString() || '',
    teamPoints: team.points?.toString() || '',
    teamGoalDiff: team.goaldifference?.toString() || '',
    divider: 'none',
    highlight: 'false',
  }));

const getPropertyValueRowsLowerThirdStats = (
  data: Ranking[],
  translations: DataProviderTranslation[]
): PropertyValue[] =>
  data.map((team) => ({
    firstColumn: team.rank.toString(),
    secondColumn: getTranslation<Ranking>(translations, team, 'contestantId', 'contestantName'),
    thirdColumn: team.matchesPlayed?.toString() || '',
    fourthColumn: team.goaldifference?.toString() || '',
    fifthColumn: team.points?.toString() || '',
  }));

const getPropertyValueSideBarStandings = (data: Ranking[]): PropertyValue[] =>
  data.map((team, i) => ({
    teamRank: team.rank.toString(),
    teamLogo: `https://cdn.mxmz.io/placeholders/UCL-logo-${team.contestantCode}.png`,
    teamName: team.contestantCode,
    teamPoints: team.points?.toString() || '',
    rankColor: i < 8 ? 'blue' : i < 24 ? 'white' : 'gray',
    highlight: 'false',
  }));

const getPropertyValueLeagueTableCL = (
  data: Ranking[],
  translations: DataProviderTranslation[]
): PropertyValue[] =>
  data.map((team) => ({
    teamRank: team.rank.toString(),
    teamName: getTranslation<Ranking>(translations, team, 'contestantId', 'contestantName'),
    teamAsterix: '',
    teamPoints: team.points?.toString() || '',
    highlight: 'false',
  }));

const getPropertyValueFixtures = (
  data: Match[],
  translations: DataProviderTranslation[]
): PropertyValue[] => {
  const result: PropertyValue[] = [];
  const matchDays = new Map<string, Match[]>();

  for (const match of data) {
    const matchDate = match.matchInfo.date;
    const matchDay = matchDays.get(matchDate) || [];
    matchDay.push(match);
    matchDays.set(matchDate, matchDay);
  }

  const entries = Array.from(matchDays.entries());
  entries.sort((a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime());

  for (const [date, matches] of entries) {
    result.push({
      sectionTitle: new Date(date).toLocaleDateString(),
      items: matches.map((match) => ({
        itemHome: getTranslation<Contestant>(
          translations,
          match.matchInfo.contestant[0],
          'id',
          'name'
        ),
        itemAway: getTranslation<Contestant>(
          translations,
          match.matchInfo.contestant[1],
          'id',
          'name'
        ),
        programChannel: 'none',
      })),
    });
  }

  return result;
};

const getPropertyValueResults = (
  data: Match[],
  translations: DataProviderTranslation[]
): PropertyValue[] =>
  data.map((match) => {
    const goals = match.liveData?.goal || [];
    const score = `${goals[0]?.homeScore || 0}-${goals[1]?.awayScore || 0}`;

    return {
      resultHomeName: getTranslation<Contestant>(
        translations,
        match.matchInfo.contestant[0],
        'id',
        'name'
      ),
      resultAwayName: getTranslation<Contestant>(
        translations,
        match.matchInfo.contestant[1],
        'id',
        'name'
      ),
      resultScore: score,
    };
  });

const getPropertyValues = (
  property: PlaylistGraphic,
  data: Ranking[] | Match[],
  translations: DataProviderTranslation[]
) => {
  switch (property.graphic.ref) {
    case 'LBarResults':
      return getPropertyValueResults(data as Match[], translations);
    case 'LBarSchedule':
      return getPropertyValueFixtures(data as Match[], translations);
    case 'LeagueTableFull':
    case 'LeagueTableHalf':
      return getPropertyValueLeagueTable(data as Ranking[], translations);
    case 'LowerThirdStats':
      return getPropertyValueRowsLowerThirdStats(data as Ranking[], translations);
    case 'SideBarStandings':
      return getPropertyValueSideBarStandings(data as Ranking[]);
    case 'LeagueTable':
      return getPropertyValueLeagueTableCL(data as Ranking[], translations);
    default:
      return [];
  }
};

const getTranslation = <T>(
  translations: DataProviderTranslation[],
  data: T,
  id: keyof T,
  property: keyof T
): string | number | PropertyValue[] => {
  const fallbackValue = data[property] as string | number | object;

  // Check if the fallback value is not a primitive type, and return an empty string if it’s an object
  if (typeof fallbackValue === 'object') {
    return '';
  }

  if (!translations.length) {
    return fallbackValue ?? '';
  }

  // Find the translation matching the given entityId and property
  const foundTranslation = translations.find(
    (t) => t.entityId === data[id] && t.property === property
  );

  // Return the found translation value if available, otherwise return the fallback or empty string
  return foundTranslation?.value ?? fallbackValue ?? '';
};

export { getDomesticCompetitions, getPropertyValues };
export { getCategory, getPropertyOptionsSportData };
