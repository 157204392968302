import { useEffect, useState } from 'react';
import { HockeyMatchControl } from 'src/types/matchControl';
import { Clock } from 'src/types/matchControl/clock';
import { Control } from 'src/types/matchControl/control';
import { HockeyContestant } from 'src/types/matchControl/contestant';
import { ShootOut as ShootOutType } from 'src/types/matchControl/shootOut';
import { CardTypes } from 'src/types/matchControl/card';
import { Score, ShortName, TeamColors, ShootOut } from '../../components';
import HockeyCards from './HockeyCards';
import VideoReferralComponent from './HockeyVideoReferral';
import ClockDisplay from '../../components/ClockDisplay';
import ChildClock from '../../components/ChildClock';
import Period from '../../components/Period';

import styles from '../../MatchControl.module.scss';

const SHOOT_OUT_ID = 'shootOut';

type Props = {
  matchControl: HockeyMatchControl;
  matchControlGraphics: Control[];
  handleDispatchControl: (control?: Control) => void;
  handleStartTime: (clock: Clock) => void;
  handleStopTime: (clock: Clock) => void;
  handleChangeTime: (clock: Clock, time: number) => void;
  handleChangeShortName: (contestant: HockeyContestant, name: string) => void;
  handleChangeScore: (contestant: HockeyContestant, amount: number) => void;
  handleChangeCards: (contestant: HockeyContestant, card: CardTypes, amount: number) => void;
  handleVideoReferral: (contestant: HockeyContestant, value: boolean) => void;
  handleChangeColor: (
    contestant: HockeyContestant,
    color: 'primaryColor' | 'secondaryColor',
    value: string
  ) => void;
  disabled?: boolean;
  handleUpdateMatchControl: (matchControl: HockeyMatchControl) => void;
  handleUpdateShootOut: (shootOut: ShootOutType) => void;
};

type ChildClockProps = {
  name: string;
  clock: Clock;
  handleStartTime: (clock: Clock) => void;
  handleStopTime: (clock: Clock) => void;
};

type PeriodProps = {
  matchControl: HockeyMatchControl;
  handleUpdateMatchControl: (matchControl: HockeyMatchControl) => void;
};

type ShootOutProps = {
  matchControl: HockeyMatchControl;
  home: HockeyContestant;
  away: HockeyContestant;
  matchControlGraphics: Control[];
  shootOutState: ShootOutType | undefined;
  handleUpdateMatchControl: (matchControl: HockeyMatchControl) => void;
  handleDispatchControl: (control?: Control) => void;
  setShootOutState: React.Dispatch<React.SetStateAction<ShootOutType | undefined>>;
  handleUpdateShootOut: (shootOut: ShootOutType) => void;
};

type ScoreProps = {
  home: HockeyContestant;
  away: HockeyContestant;
  handleChangeShortName: (contestant: HockeyContestant, name: string) => void;
  handleChangeScore: (contestant: HockeyContestant, amount: number) => void;
  handleChangeColor: (
    contestant: HockeyContestant,
    color: 'primaryColor' | 'secondaryColor',
    value: string
  ) => void;
};

const Hockey = ({
  matchControl,
  matchControlGraphics,
  handleChangeColor,
  handleChangeShortName,
  handleChangeScore,
  handleChangeCards,
  handleVideoReferral,
  handleChangeTime,
  handleDispatchControl,
  handleStartTime,
  handleStopTime,
  handleUpdateMatchControl,
  handleUpdateShootOut,
}: Props) => {
  const { clocks, contestants, shootOut } = matchControl;
  const [mainClock, shotClock, shootoutClock] = clocks;
  const [home, away] = contestants;
  const [shootOutState, setShootOutState] = useState<ShootOutType | undefined>(shootOut);

  useEffect(() => {
    setShootOutState(shootOut);
  }, [matchControl.shootOut]);

  return (
    <div className={styles.MainWrapper}>
      <ClockDisplay
        clock={mainClock}
        matchControlGraphics={matchControlGraphics}
        extraTime={mainClock.extraTime}
        showExtraTime={false}
        precision="minutes"
        handleDispatchControl={handleDispatchControl}
        handleStartTime={handleStartTime}
        handleStopTime={handleStopTime}
        handleChangeTime={(time) => handleChangeTime(mainClock, mainClock.clockTime + time)}
      />
      <DividerComponent />
      <ScoreComponent
        home={home}
        away={away}
        handleChangeColor={handleChangeColor}
        handleChangeScore={handleChangeScore}
        handleChangeShortName={handleChangeShortName}
      />
      <DividerComponent />
      <HockeyCards home={home} away={away} handleChangeCards={handleChangeCards} />
      <DividerComponent />
      <VideoReferralComponent home={home} away={away} handleVideoReferral={handleVideoReferral} />
      <DividerComponent />
      <PeriodComponent
        matchControl={matchControl}
        handleUpdateMatchControl={handleUpdateMatchControl}
      />
      {shotClock && (
        <ChildClockComponent
          name="Shot Clock"
          clock={shotClock}
          handleStartTime={handleStartTime}
          handleStopTime={handleStopTime}
        />
      )}
      {shootoutClock && (
        <ChildClockComponent
          name="Shootout Clock"
          clock={shootoutClock}
          handleStartTime={handleStartTime}
          handleStopTime={handleStopTime}
        />
      )}
      <DividerComponent />
      <ShootOutComponent
        home={home}
        away={away}
        shootOutState={shootOutState}
        matchControl={matchControl}
        matchControlGraphics={matchControlGraphics}
        handleDispatchControl={handleDispatchControl}
        handleUpdateMatchControl={handleUpdateMatchControl}
        handleUpdateShootOut={handleUpdateShootOut}
        setShootOutState={setShootOutState}
      />
    </div>
  );
};

const ScoreComponent = ({
  home,
  away,
  handleChangeShortName,
  handleChangeColor,
  handleChangeScore,
}: ScoreProps) => (
  <div className={styles.ItemWrapper}>
    <div className={styles.CurrentScoreWrapper}>
      <div className="flex flex-col justify-between items-center gap-2">
        <ShortName
          shortName={home.shortName}
          handleChangeShortName={(name) => handleChangeShortName(home, name)}
        />
        <TeamColors
          primaryColor={home.primaryColor}
          secondaryColor={home.secondaryColor}
          handleChangePrimaryColor={(color) => handleChangeColor(home, 'primaryColor', color)}
          handleChangeSecondaryColor={(color) => handleChangeColor(home, 'secondaryColor', color)}
        />
      </div>
      <Score
        key={home.id}
        goals={home.score.points}
        handleChangeScore={(amount) => handleChangeScore(home, amount)}
      />
      <Score
        key={away.id}
        inverted
        goals={Number(away.score.points)}
        handleChangeScore={(amount) => handleChangeScore(away, amount)}
      />
      <div className="flex flex-col justify-between items-center gap-2">
        <ShortName
          inverted
          shortName={away.shortName}
          handleChangeShortName={(name) => handleChangeShortName(away, name)}
        />
        <TeamColors
          primaryColor={away.primaryColor}
          secondaryColor={away.secondaryColor}
          handleChangePrimaryColor={(color) => handleChangeColor(away, 'primaryColor', color)}
          handleChangeSecondaryColor={(color) => handleChangeColor(away, 'secondaryColor', color)}
        />
      </div>
    </div>
  </div>
);

const PeriodComponent = ({ matchControl, handleUpdateMatchControl }: PeriodProps) => (
  <div className="flex items-center gap-2">
    <div className="flex-auto min-w-0 font-semibold"> Period</div>
    <div>
      <Period
        period={matchControl.period}
        handleAddPeriod={() =>
          handleUpdateMatchControl({ ...matchControl, period: matchControl.period + 1 })
        }
        handleRemovePeriod={() =>
          handleUpdateMatchControl({
            ...matchControl,
            period: Math.max(1, matchControl.period - 1),
          })
        }
      />
    </div>
  </div>
);

const ChildClockComponent = ({ name, clock, handleStartTime, handleStopTime }: ChildClockProps) => {
  const handleToggleClock = (clock: Clock) => {
    if (!clock.clockTimeUpdatedAt) {
      handleStartTime(clock);
    } else {
      handleStopTime(clock);
    }
  };

  return (
    <div className="flex items-center gap-2">
      <div className="flex-auto min-w-0 font-semibold"> {name}</div>
      <div>
        <ChildClock clock={clock} handleToggleClock={handleToggleClock} />
      </div>
    </div>
  );
};

const ShootOutComponent = ({
  matchControl,
  home,
  away,
  matchControlGraphics,
  shootOutState,
  handleUpdateMatchControl,
  handleDispatchControl,
  setShootOutState,
  handleUpdateShootOut,
}: ShootOutProps) => (
  <ShootOut
    hasShootOut={matchControl.hasShootOut}
    handleToggleShootOut={() =>
      handleUpdateMatchControl({
        ...matchControl,
        hasShootOut: !matchControl.hasShootOut,
      })
    }
    isLive={Boolean(
      matchControlGraphics?.some((control) => control.controlId === SHOOT_OUT_ID && control.live)
    )}
    handleClickLive={() =>
      handleDispatchControl(matchControlGraphics?.find((c) => c.controlId === SHOOT_OUT_ID))
    }
    shootOutTaker={shootOutState?.taker || 'home'}
    home={{ shortName: home.shortName, attempts: shootOutState?.home || [] }}
    away={{ shortName: away.shortName, attempts: shootOutState?.away || [] }}
    handleUpdateShootOut={(shootOut) => setShootOutState(shootOut)}
    handleClickUpdate={() => shootOutState && handleUpdateShootOut(shootOutState)}
  />
);

const DividerComponent = () => <div className={styles.Divider} />;

export default Hockey;
