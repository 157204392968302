import { HockeyContestant } from 'src/types/matchControl/contestant';
import { CardTypes } from 'src/types/matchControl/card';

import { Cards } from '../../components';

import styles from '../../MatchControl.module.scss';

type CardProps = {
  home: HockeyContestant;
  away: HockeyContestant;
  handleChangeCards: (contestant: HockeyContestant, card: CardTypes, amount: number) => void;
};

const CardComponent = ({ home, away, handleChangeCards }: CardProps) => (
  <div className={styles.ItemWrapper}>
    <div className={styles.CurrentCardWrapper}>
      <div className={styles.HockeyCardWrapper}>
        <Cards
          card={CardTypes.RED_CARD}
          amount={home.redCards || 0}
          handleChangeCards={(card, amount) => handleChangeCards(home, card, amount)}
        />
        <Cards
          card={CardTypes.GREEN_CARD}
          amount={home.greenCards || 0}
          handleChangeCards={(card, amount) => handleChangeCards(home, card, amount)}
        />
        <Cards
          card={CardTypes.YELLOW_CARD}
          amount={home.yellowCards || 0}
          handleChangeCards={(card, amount) => handleChangeCards(home, card, amount)}
        />
      </div>
      <div className={styles.HockeyCardWrapper}>
        <Cards
          card={CardTypes.RED_CARD}
          amount={away.redCards || 0}
          handleChangeCards={(card, amount) => handleChangeCards(away, card, amount)}
        />
        <Cards
          card={CardTypes.GREEN_CARD}
          amount={away.greenCards || 0}
          handleChangeCards={(card, amount) => handleChangeCards(away, card, amount)}
        />
        <Cards
          card={CardTypes.YELLOW_CARD}
          amount={away.yellowCards || 0}
          handleChangeCards={(card, amount) => handleChangeCards(away, card, amount)}
        />
      </div>
    </div>
  </div>
);

export default CardComponent;
