import classNames from 'classnames';
import { HockeyContestant } from 'src/types/matchControl/contestant';
import { MatchControlButton } from '../../components';
import styles from '../../MatchControl.module.scss';

type VideoReferralButtonProps = {
  contestant: HockeyContestant;
  onToggle: () => void;
};

type VideoReferralProps = {
  home: HockeyContestant;
  away: HockeyContestant;
  handleVideoReferral: (contestant: HockeyContestant, value: boolean) => void;
};

const VideoReferralButton = ({ contestant, onToggle }: VideoReferralButtonProps) => {
  const buttonClass = classNames({
    [styles.VideoReferralEnabled]: contestant.videoReferral,
    [styles.VideoReferralDisabled]: !contestant.videoReferral,
  });

  return (
    <MatchControlButton variant="icon" className={buttonClass} onClick={onToggle}>
      Video Ref
    </MatchControlButton>
  );
};

const VideoReferralComponent = ({ home, away, handleVideoReferral }: VideoReferralProps) => {
  const handleToggle = (contestant: HockeyContestant) => {
    handleVideoReferral(contestant, !contestant.videoReferral);
  };

  return (
    <div className={styles.ItemWrapper}>
      <div className={styles.CurrentCardWrapper}>
        {[home, away].map((contestant) => (
          <div key={contestant.id} className={styles.HockeyCardWrapper}>
            <VideoReferralButton
              contestant={contestant}
              onToggle={() => handleToggle(contestant)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoReferralComponent;
