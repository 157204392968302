import { useEffect, useMemo, useRef, useState } from 'react';
import {
  isFile,
  Directory,
  MediaLibrary,
  Item,
  MediaLibrarySearchOption,
} from '@southfields-digital/mpxlive-components';
import { recursivelyFindOpenDirectory } from './GraphicMediaLibrary.helpers';
import { GraphicMediaLibraryProps } from './GraphicMediaLibrary.types';
import { useSessionStorage } from 'src/hooks';
import { connectToMediaLibraryState } from 'src/containers/MediaLibrary';
import { MEDIA_LIBRARY_FILES_PAGE_SIZE } from 'src/config/mediaLibrary';

const workspaceDirectory: Directory = { id: 'root', name: 'Workspace', preventDeletion: true };

const GraphicMediaLibrary = ({
  count,
  deleteFile,
  directories,
  files,
  getDataByDirectory,
  isLoading,
  onFileSelect,
  rundownId,
  searchCount,
  searchDataByDirectory,
  searchResults,
  setCanHideLibrary,
  uploadFiles,
}: GraphicMediaLibraryProps) => {
  const pageSize = MEDIA_LIBRARY_FILES_PAGE_SIZE;

  // Session storage
  const [currentPath, setCurrentPath] = useSessionStorage(
    `medialibrary:initialpath:${rundownId || 'general'}`,
    [workspaceDirectory]
  );

  // State
  const [currentDirectory, setCurrentDirectory] = useState<Directory>(
    currentPath[currentPath.length - 1]
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [directoryName, setDirectoryName] = useState<string>(currentDirectory?.name);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [searchOption, setSearchOption] = useState<MediaLibrarySearchOption>({
    id: currentDirectory.id,
    label: currentDirectory.name,
  });
  const [searchTerm, setSearchTerm] = useState<string>('');

  // Refs
  // Current directory is volatile and likely to change during API requests, so define a ref for use in callbacks
  const guaranteedCurrentDirectory = useRef<Directory>(currentDirectory);
  // Current page is volatile and likely to change during API requests, so define a ref for use in callbacks
  const guaranteedCurrentPage = useRef<number>(currentPage);
  const previousSearchTerm = useRef<string>('');
  const switchingDirectories = useRef<boolean>(false);

  // Memoized data
  const activeIds = useMemo(() => currentPath.map(({ id }: { id: string }) => id), [currentPath]);
  const initialBreadcrumbs: (Directory | undefined)[] = useMemo(
    () => (currentPath.length > 0 ? currentPath : directories[0] ? [directories[0]] : []),
    [currentPath, directories]
  );
  const openDirectory = useMemo(
    () => recursivelyFindOpenDirectory(directories || [], activeIds),
    [directories, activeIds]
  );
  const searchOptions = useMemo(
    () =>
      currentDirectory.id !== 'root'
        ? [workspaceDirectory, { id: currentDirectory.id, name: 'Current folder' }].map(
            ({ id, name }) => ({ id, label: name })
          )
        : [],
    [currentDirectory.id]
  );
  const loadingNextDirectory = useMemo(
    () => isLoading && switchingDirectories.current,
    [isLoading]
  );
  const data: Item[] = useMemo(
    () =>
      loadingNextDirectory
        ? []
        : searchTerm
        ? searchResults
        : [...(openDirectory?.directories ?? []), ...files],
    [files, loadingNextDirectory, openDirectory, searchTerm, searchResults]
  );

  // Handlers
  const getDataHandler = (directory: Directory) => {
    switchingDirectories.current = true;

    // Update state
    setSearchTerm('');
    setCurrentPage(1);
    setCurrentDirectory(directory || workspaceDirectory);

    // Fetch data
    getDataByDirectory({
      id: directory.id || workspaceDirectory.id,
      pagination: { offset: 0, pageSize },
      ...(currentPath ? { initialPath: currentPath } : {}),
    });
  };

  const submitFilesHandler = (fileList: FileList | null) => {
    if (fileList && fileList.length > 0) {
      uploadFiles({
        directoryId: currentDirectory.id === 'root' ? 'root' : currentDirectory.id,
        fileList,
        callback: (uploadedFileCount: number) => {
          // Refresh data if current directory is the same as the one the files were uploaded to (currentDirectory is volatile)
          if (guaranteedCurrentDirectory.current.id === currentDirectory.id) {
            // Correct for fixed page size and variable uploaded file count
            const currentFileLength = uploadedFileCount + guaranteedCurrentPage.current * pageSize;
            const correctedPageSize = currentFileLength + (currentFileLength % pageSize);
            const currentPageDiff = correctedPageSize / pageSize - guaranteedCurrentPage.current;

            // Correct the current page size to keep infinite scroll working
            if (currentPageDiff > 0) {
              setCurrentPage(guaranteedCurrentPage.current + currentPageDiff);
            }

            getDataByDirectory({
              id: currentDirectory.id,
              pagination: { offset: 0, pageSize: correctedPageSize },
              ...(currentPath ? { initialPath: currentPath } : {}),
            });
          }
        },
      });
    }
  };

  const onItemClickHandler = (item: Item) => {
    if (isFile(item)) {
      const fullImage = item.data.find((file) => file.type === 'full');
      onFileSelect(fullImage?.url ?? '');
    } else {
      const putAtIndex = activeIds.includes(item.id)
        ? activeIds.indexOf(item.id)
        : activeIds.length;
      const shouldInsertItem = item.id && item.id !== workspaceDirectory.id;
      const updatedPath = [
        workspaceDirectory,
        ...(currentPath ? currentPath.slice(1, putAtIndex) : []),
        ...(shouldInsertItem ? [{ id: item.id, name: item.name }] : []),
      ];

      setCurrentPath(updatedPath);
    }
  };

  // useEffect hooks
  useEffect(() => {
    getDataHandler(currentPath ? currentPath[currentPath.length - 1] : workspaceDirectory);
    setCurrentDirectory(currentPath[currentPath.length - 1]);
  }, [currentPath]);

  useEffect(() => {
    guaranteedCurrentDirectory.current = currentDirectory;
    setDirectoryName(currentDirectory.name);
    setSearchOption({ id: currentDirectory.id, label: currentDirectory.name });
  }, [currentDirectory]);

  useEffect(() => {
    if (searchTerm) {
      setHasMore(searchCount > files.length && searchCount > pageSize * currentPage);
    } else {
      setHasMore(count > files.length && count > pageSize * currentPage);
    }
  }, [count, files, currentPage, searchCount, searchTerm]);

  useEffect(() => {
    const searchCleared = !searchTerm && previousSearchTerm.current.length > searchTerm.length;

    // Update state
    setCurrentPage(1);

    // Search if search term is not empty
    if (searchTerm) {
      switchingDirectories.current = false;

      setCanHideLibrary(false);
      searchDataByDirectory({
        id: searchOption.id,
        pagination: { offset: 0, pageSize },
        searchTerm,
        shouldReset: 'both',
      });
    }

    // Get data normally if search term is empty
    if (searchCleared) {
      switchingDirectories.current = true;

      setCanHideLibrary(true);
      getDataByDirectory({
        id: currentDirectory.id,
        pagination: { offset: 0, pageSize },
        ...(currentPath ? { initialPath: currentPath } : {}),
      });
    }

    previousSearchTerm.current = searchTerm;
  }, [currentDirectory, currentPath, searchTerm, searchOption]);

  return (
    <MediaLibrary
      key={`${currentDirectory.id}_${directoryName}`}
      deleteFile={({ id }) => deleteFile(id)}
      directoryName={directoryName}
      hasMore={hasMore}
      initialBreadcrumbs={initialBreadcrumbs}
      isLoading={isLoading}
      itemsLength={pageSize * currentPage}
      libraryData={data}
      loadMore={() => {
        setCurrentPage((prevPage) => {
          switchingDirectories.current = false;

          const nextPage = prevPage + 1;
          const nextPageZeroIndexed = nextPage - 1; // For clarity

          if (searchTerm) {
            searchDataByDirectory({
              id: searchOption.id,
              pagination: {
                offset: nextPageZeroIndexed * pageSize,
                pageSize,
              },
              searchTerm,
            });
          } else {
            getDataByDirectory({
              id: currentDirectory.id,
              pagination: {
                offset: nextPageZeroIndexed * pageSize,
                pageSize,
              },
              ...(currentPath ? { initialPath: currentPath } : {}),
            });
          }

          return nextPage;
        });
      }}
      onItemClick={onItemClickHandler}
      searchOption={searchOption}
      searchOptions={searchTerm ? searchOptions : []}
      searchQuery={searchTerm}
      setSearchOption={(searchOption: MediaLibrarySearchOption) => {
        setSearchOption(searchOption);
        setDirectoryName(searchOption?.label);
      }}
      setSearchQuery={setSearchTerm}
      supportedMimeTypes={['image/jpg', 'image/jpeg', 'image/png']}
      uploadFiles={submitFilesHandler}
      variant="compact"
    />
  );
};

export default connectToMediaLibraryState(GraphicMediaLibrary);
