import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isValid } from 'date-fns';
import { Button, FormGroup, Input, Text } from '@southfields-digital/mpxlive-components';
import { StateType } from 'src/redux/reducers';
import { REQUEST_GET_SPORT_DATA_FIXTURES } from 'src/redux/reducers/sportData';
import { getDomesticCompetitions, getPropertyValues } from '../utils';
import { getRepeaterItems } from 'src/components/GraphicPropertiesForm';
import { toDatetimeLocal } from 'src/utils';

import StandingsSelect from '../Standings/StandingsSelect';
import Repeater from 'src/components/Repeater';

import styles from './Fixtures.module.scss';

type Props = {
  handleImportData: (values: PropertyValue[]) => void;
  selectedGraphic: PlaylistGraphic;
  selectedProperty: RepeaterProperties;
};

export default function Fixtures({ handleImportData, selectedGraphic, selectedProperty }: Props) {
  const dispatch = useDispatch();

  const { fixtures, tournamentCalendar } = useSelector((state: StateType) => state.sportData);
  const { data: translations } = useSelector((state: StateType) => state.dataProviderTranslation);

  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  const [tmcl, setTmcl] = useState<string>(
    fixtures?.data?.match[0]?.matchInfo.tournamentCalendar.id || ''
  );

  const competitions = useMemo(
    () => getDomesticCompetitions(tournamentCalendar.data?.competition),
    [tournamentCalendar.data?.competition]
  );

  useEffect(() => {
    if (!tmcl || !startDate || !endDate) {
      return;
    }

    if (!isValid(new Date(startDate)) || !isValid(new Date(endDate))) {
      return;
    }

    dispatch({
      type: REQUEST_GET_SPORT_DATA_FIXTURES,
      payload: {
        tmcl,
        startDate: toDatetimeLocal(startDate).localDateTime,
        endDate: toDatetimeLocal(endDate).localDateTime,
      },
    });
  }, [tmcl, startDate, endDate]);

  const propertyValues = getPropertyValues(
    selectedGraphic,
    fixtures.data?.match || [],
    translations ?? []
  );

  const repeaterValues = propertyValues.length
    ? getRepeaterItems(selectedProperty, propertyValues)
    : [];

  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <Text as="h3">Fixtures</Text>
        <Button onClick={() => handleImportData(propertyValues)}>Import</Button>
      </div>
      <div className={styles.Content}>
        <div className="grid grid-flow-col gap-2 grid-columns-[60%_20%_20%]">
          <StandingsSelect
            label="Selected league"
            value={tmcl}
            options={competitions}
            onChange={(e) => setTmcl(e.currentTarget.value)}
          />
          <FormGroup label="Start Date" htmlFor="startDate">
            <Input
              id="startDate"
              type="date"
              value={startDate}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setStartDate(e.target.value)}
            />
          </FormGroup>
          <FormGroup label="End Date" htmlFor="endDate">
            <Input
              id="endDate"
              type="date"
              value={endDate}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEndDate(e.target.value)}
            />
          </FormGroup>
        </div>
        <Repeater
          id=""
          onChangeRows={() => {}}
          items={repeaterValues}
          isDisabled
          excludedIds={['divider', 'highlight']}
        />
      </div>
    </div>
  );
}
