import { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Button, Icon, Text } from '@southfields-digital/mpxlive-components';
import LivePulse from 'src/components/LivePulse';
import RundownCard, { RundownCardType } from 'src/components/RundownCard';
import {
  LiveRundownFlow,
  LiveRundownGroup,
  type LiveRundownCardsProps,
  type LiveRundownProps,
} from './LiveRundowns.types';
import styles from './LiveRundowns.module.scss';
import { isFuture, isToday, isYesterday } from 'date-fns';

const LiveRundownCards = ({
  currentlyOpenRundownId,
  location,
  navigate,
  rundowns,
}: LiveRundownCardsProps) => {
  if (rundowns.length === 0) {
    return <Text as="p">No live rundowns</Text>;
  }

  return rundowns.map((rundown) => {
    const rundownIsCurrentlyOpen = rundown.id === currentlyOpenRundownId;

    return (
      <RundownCard
        key={rundown.id}
        rundown={rundown}
        type={RundownCardType.COMPACT}
        className={classNames(styles.LiveRundownCard, {
          [styles.IsCurrentlyOpenRundown]: rundownIsCurrentlyOpen,
        })}
        handleClickCard={() => {
          if (rundownIsCurrentlyOpen) {
            return;
          }

          navigate(`/rundown/${rundown.id}`, {
            state: {
              flowType: LiveRundownFlow.TAKE_LIVE_RUNDOWN_OFFLINE,
              returnPath: location.pathname,
            },
          });
        }}
      />
    );
  });
};

export default function LiveRundowns({
  currentlyOpenRundownId,
  getLiveRundowns,
  liveRundowns,
}: LiveRundownProps) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isRegularFilter = ({ isPersistent }: Rundown) => !isPersistent;
  const isPersistentFilter = ({ isPersistent }: Rundown) => isPersistent;

  const liveToday = useMemo(
    () =>
      liveRundowns.data
        ?.filter(isRegularFilter)
        .filter(({ liveSince }) => liveSince && isToday(new Date(liveSince))) || [],
    [liveRundowns]
  );

  const liveYesterday = useMemo(
    () =>
      liveRundowns.data
        ?.filter(isRegularFilter)
        .filter(({ liveSince }) => liveSince && isYesterday(new Date(liveSince))) || [],
    [liveRundowns]
  );

  const liveBefore = useMemo(
    () =>
      liveRundowns.data?.filter(isRegularFilter).filter(({ liveSince }) => {
        const liveDate = liveSince && new Date(liveSince);

        return liveDate && !isToday(liveDate) && !isYesterday(liveDate) && !isFuture(liveDate);
      }) || [],
    [liveRundowns]
  );

  const livePersistent = useMemo(
    () => liveRundowns.data?.filter(isPersistentFilter) || [],
    [liveRundowns]
  );

  const liveRundownsCount = useMemo(() => liveRundowns.data?.length || 0, [liveRundowns]);

  const liveRundownsGroups: LiveRundownGroup[] = [
    {
      title: 'Today',
      rundowns: liveToday,
    },
    {
      title: 'Yesterday',
      rundowns: liveYesterday,
    },
    {
      title: 'Older',
      rundowns: liveBefore,
    },
    {
      title: 'Persistent',
      rundowns: livePersistent,
    },
  ];

  useEffect(() => {
    const resetEditingOutputOnEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setOpen(false);
      }
    };

    document.addEventListener('keydown', resetEditingOutputOnEscape, true);

    return function unmount() {
      document.removeEventListener('keydown', resetEditingOutputOnEscape, true);
    };
  }, []);

  useEffect(() => {
    // Use polling
    const liveRundownInterval = setInterval(() => {
      getLiveRundowns();
    }, 10000);

    getLiveRundowns();

    return function cleanUp() {
      clearInterval(liveRundownInterval);
    };
  }, []);

  useEffect(() => {
    if (open) {
      getLiveRundowns();
    }
  }, [open]);

  return (
    <>
      <Button as="button" className={styles.LiveRundownsButton} onClick={() => setOpen(true)}>
        <Icon icon="queue" weight="regular" size={24} />

        <LivePulse
          className={classNames(styles.LivePulse)}
          dotClassName={classNames({
            [styles.NoLiveRundowns]: liveRundownsCount === 0,
          })}
          pingClassName={classNames({
            [styles.NoLiveRundowns]: liveRundownsCount === 0,
          })}
          wrapperClassName={styles.LivePulseWrapper}
        />
      </Button>

      {createPortal(
        <>
          <div
            className={classNames(styles.LiveRundownsDrawerOverlay, {
              [styles.LiveRundownsDrawerOverlayVisible]: open,
            })}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              setOpen(false);
            }}
          />

          <div
            className={classNames(styles.LiveRundownsDrawer, {
              [styles.LiveRundownsDrawerOpen]: open,
            })}
          >
            <div className={styles.LiveRundownsDrawerHeader}>
              <Text as={'h2'}>Currently Live ({liveRundownsCount})</Text>

              <Button
                as="button"
                className={styles.LiveRundownsDismissButton}
                iconProps={{ icon: 'x', weight: 'regular', size: 18 }}
                onClick={() => setOpen(false)}
              />
            </div>

            <div className={styles.LiveRundownGroups}>
              {liveRundownsGroups.map(({ rundowns, title }, index) => (
                <div key={index} className={styles.LiveRundownCardWrapper}>
                  <Text as="h3">{title}</Text>

                  <LiveRundownCards
                    currentlyOpenRundownId={currentlyOpenRundownId}
                    navigate={navigate}
                    location={location}
                    rundowns={rundowns}
                  />
                </div>
              ))}
            </div>
          </div>
        </>,
        document.body
      )}
    </>
  );
}
