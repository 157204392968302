import { all, fork } from 'redux-saga/effects';

// Import sagas
import canvas from './canvas';
import control from './control';
import desktopApp from './desktopApp';
import graphic from './graphic';
import graphicPackage from './graphicPackage';
import output from './output';
import playlist from './playlist';
import playlistGraphic from './playlistGraphic';
import rundown from './rundown';
import rundownTemplate from './rundownTemplate';
import user from './user';
import mediaLibrary from './mediaLibrary';
import notification from './notification';
import matchControl from './matchControl';
import sportData from './sportData';
import dataProviderTranslation from './dataProviderTranslation';

// Export root saga
export default function* root() {
  yield all([
    fork(canvas),
    fork(graphic),
    fork(control),
    fork(desktopApp),
    fork(graphicPackage),
    fork(output),
    fork(playlist),
    fork(playlistGraphic),
    fork(rundown),
    fork(rundownTemplate),
    fork(user),
    fork(mediaLibrary),
    fork(notification),
    fork(matchControl),
    fork(sportData),
    fork(dataProviderTranslation),
  ]);
}
