import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DataProviderTranslationsResponseType,
  DataProviderTranslationStateType,
  DATA_PROVIDER_TRANSLATION,
} from './types';

const dataProviderTranslationInitialState: DataProviderTranslationStateType = {
  data: null,
  loading: true, // start with loading state to avoid flash
  error: null,
};

export const dataProviderTranslationSlice = createSlice({
  name: DATA_PROVIDER_TRANSLATION,
  initialState: dataProviderTranslationInitialState,
  // Apply state changes
  reducers: {
    requestGetDataProviderTranslations: (
      dataProviderTranslation: DataProviderTranslationStateType
    ) => {
      dataProviderTranslation.loading = true;
    },
    receiveGetDataProviderTranslations: (
      dataProviderTranslation: DataProviderTranslationStateType,
      { payload }: PayloadAction<DataProviderTranslationsResponseType>
    ) => {
      dataProviderTranslation.loading = false;
      dataProviderTranslation.data = payload;
    },
    failedGetDataProviderTranslations: (
      dataProviderTranslation: DataProviderTranslationStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      dataProviderTranslation.loading = false;
      dataProviderTranslation.error = error;
    },
  },
});

const actions = dataProviderTranslationSlice.actions;
const dataProviderTranslationReducer = dataProviderTranslationSlice.reducer;

export { actions, dataProviderTranslationReducer };
