import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { NavigateFunction } from 'react-router';
import { MatchControl } from 'src/types/matchControl';
import { SportCategory } from 'src/types/matchControl/sport';

import { Loader, Text, AccordionForm, Button } from '@southfields-digital/mpxlive-components';
import { Section } from '@southfields-digital/mpxlive-components/build/components/AccordionForm/types';
import { BaseLayoutContext, BaseLayoutContextType } from 'src/layouts/BaseLayout';
import CanvasGraphicsForm from 'src/components/CanvasGraphicsForm';
import RundownTemplatePickerForm from 'src/components/RundownTemplatePickerForm';
import RundownForm from 'src/components/RundownForm';
import RundownMatchControlForm from 'src/components/RundownMatchControl';
import CanvasForm from 'src/components/CanvasForm';
import SlideOut from 'src/components/SlideOut';
import GraphicMediaLibrary from 'src/components/GraphicMediaLibrary';

import styles from './RundownForm.module.scss';

type RundownFormPageProps = {
  canvasesData: Canvas[];
  canvasBackgroundsData: CanvasBackground[];
  error: string | null;
  getCanvases: () => void;
  getCanvasBackgrounds: () => void;
  getGraphicPackages: (deprecated: boolean) => void;
  getGraphicsByGraphicPackageId: (graphicPackageId: string) => void;
  getOutputs: () => void;
  getRundown: () => void;
  getRundownTemplates: () => void;
  getMatchControlTypes: () => void;
  getMatchControl: (payload: string) => void;
  matchControlTypesData: SportCategory[];
  matchControlSkeletonData: MatchControl | null;
  graphicPackagesData: GraphicPackage[];
  graphicsData: Graphic[];
  handleSubmit: (payload: Partial<Rundown>, navigate?: NavigateFunction) => void;
  loadingRundownData: boolean;
  loadingRundownTemplatesData?: boolean;
  outputsData: Output[];
  rundownData: Partial<Rundown>;
  rundownTemplatesData: RundownTemplate[];
  rundownId?: string;
  submitting: boolean;
};

const SECTION_IDS = {
  TEMPLATE: 'template',
  RUNDOWN: 'rundown',
  GRAPHICS: 'graphics',
  BUNDLE: 'bundle',
  MATCH_CONTROL: 'matchControl',
  CANVAS: 'canvas',
} as const;

export default function RundownFormPage({
  canvasesData,
  canvasBackgroundsData,
  error,
  getCanvases,
  getCanvasBackgrounds,
  getGraphicPackages,
  getGraphicsByGraphicPackageId,
  getOutputs,
  getRundown,
  getRundownTemplates,
  getMatchControlTypes,
  getMatchControl,
  matchControlTypesData,
  matchControlSkeletonData,
  graphicPackagesData,
  graphicsData,
  handleSubmit,
  loadingRundownData,
  outputsData,
  rundownData,
  rundownTemplatesData,
  rundownId,
  submitting,
}: RundownFormPageProps) {
  const { setWithContainer } = useContext<BaseLayoutContextType | null>(
    BaseLayoutContext
  ) as BaseLayoutContextType;
  const navigate = useNavigate();

  const createOrUpdate = rundownId ? 'Update' : 'Create';
  const [searchParams] = useSearchParams();
  const [state, setState] = useState<Partial<Rundown>>(rundownData);
  const [selectedSectionId, setSelectedSectionId] = useState<string>(SECTION_IDS.TEMPLATE);
  const [visitedSections, setVisitedSections] = useState<string[]>(
    createOrUpdate === 'Update' ? [...Object.values(SECTION_IDS)] : [SECTION_IDS.TEMPLATE]
  );
  const [editingTemplate, setEditingTemplate] = useState(false);
  const [mediaLibraryState, setMediaLibraryState] = useState<{ open: boolean; onClose?: Function }>(
    { open: false, onClose: () => {} }
  );

  // Side effect to handle setting the selected section id when selecting a rundown template
  useEffect(() => {
    if (state.rundownTemplateId && selectedSectionId === SECTION_IDS.TEMPLATE) {
      handleChangeSection(SECTION_IDS.RUNDOWN);
    }
  }, [state.rundownTemplateId]);

  const updateState = (partialState: Partial<Rundown>) => {
    setState((prevState: Partial<Rundown>) => {
      const newState = {
        ...prevState,
        ...partialState,
        isPersistent: prevState.isPersistent || searchParams.get('persistent') === 'true',
      };
      return newState;
    });
  };

  const handleChangeSection = (id: string, sections?: Section[]) => {
    // Close if we click on the open section
    if (id === selectedSectionId) {
      setSelectedSectionId('');
      return;
    }

    // Check if section we want to navigate to is locked if we provided all the sections
    if (sections) {
      const section = sections.find((section) => section.id === id);
      if (section?.locked) return;
    }

    setVisitedSections((prevVisitedSections) => {
      if (!prevVisitedSections.includes(id)) {
        return [...prevVisitedSections, id];
      }

      return prevVisitedSections;
    });

    setSelectedSectionId(id);
  };

  const handleClickNext = (sections: Section[]) => {
    const currentSectionIndex = sections.findIndex((section) => section.id === selectedSectionId);
    const nextSection = sections[currentSectionIndex + 1];

    if (!nextSection) return;
    handleChangeSection(nextSection.id, sections);
  };

  const handleSelectImage = (src: string) => {
    if (mediaLibraryState.onClose) {
      mediaLibraryState.onClose(src);
    }
    setMediaLibraryState({ open: false, onClose: () => {} });
  };

  useEffect(() => {
    setWithContainer(true);
    getGraphicPackages(!!rundownId);
    getMatchControlTypes();
    getRundown();
    getRundownTemplates();

    return () => {
      setWithContainer(false);
    };
  }, []);

  useEffect(() => {
    if (rundownId) {
      setState(rundownData);
    }
  }, [rundownId, rundownData]);

  useEffect(() => {
    if (state?.graphicPackageId) {
      getCanvases();
      getCanvasBackgrounds();
      getOutputs();
      getGraphicsByGraphicPackageId(state?.graphicPackageId);
      getMatchControlTypes();
    }
  }, [state?.graphicPackageId]);

  useEffect(() => {
    updateState({ matchControl: matchControlSkeletonData });
  }, [matchControlSkeletonData]);

  if (loadingRundownData) return <Loader centeredFullscreen />;
  if (error) return <Text as="h1">Error: {error}</Text>;
  if (rundownId && !rundownData) return <Text as="h1">Could not get rundown data</Text>;

  const accordionFormTitle = `${createOrUpdate} rundown`;

  const rundownSectionCompleted = Boolean(
    state.name &&
      state.start &&
      state.graphicPackageId &&
      visitedSections.includes(SECTION_IDS.RUNDOWN)
  );
  const graphicsSectionCompleted = Boolean(
    state.canvases && rundownSectionCompleted && visitedSections.includes(SECTION_IDS.GRAPHICS)
  );
  const bundleSectionCompleted = Boolean(
    state.canvases && graphicsSectionCompleted && visitedSections.includes(SECTION_IDS.BUNDLE)
  );
  const matchControlSectionCompleted = Boolean(
    bundleSectionCompleted && visitedSections.includes(SECTION_IDS.MATCH_CONTROL)
  );
  const canvasSectionCompleted = Boolean(
    state.canvases && matchControlSectionCompleted && visitedSections.includes(SECTION_IDS.CANVAS)
  );

  const rundownSectionLocked = Boolean(!state.rundownTemplateId);
  const graphicsSectionLocked = Boolean(!state.graphicPackageId || !rundownSectionCompleted);
  const bundleSectionLocked = Boolean(!state.graphicPackageId || !rundownSectionCompleted);
  const canvasSectionLocked = Boolean(!state.graphicPackageId || !rundownSectionCompleted);
  const matchControlSectionLocked = Boolean(!state.graphicPackageId || !rundownSectionCompleted);

  const canSubmit =
    rundownSectionCompleted &&
    graphicsSectionCompleted &&
    canvasSectionCompleted &&
    matchControlSectionCompleted;

  const sections: Section[] = [
    {
      id: SECTION_IDS.TEMPLATE,
      type: 'visible',
      completed: Boolean(state.rundownTemplateId),
      locked: false,
      form: (
        <RundownTemplatePickerForm
          editing={editingTemplate}
          editingDisabled={Boolean(rundownId)}
          handleChangeEditing={(value) => {
            if (value) {
              handleChangeSection(SECTION_IDS.TEMPLATE);
            }

            setEditingTemplate(value);
          }}
          rundownTemplates={rundownTemplatesData}
          state={state.rundownTemplateId || ''}
          stateUpdated={(update) => {
            updateState(update);

            if (update.rundownTemplateId) {
              setEditingTemplate(false);
              handleChangeSection(SECTION_IDS.RUNDOWN);
            }

            if (update.matchControl?.sport) {
              getMatchControl(update.matchControl.sport);
            }
          }}
        />
      ),
      info: (
        <>
          <Text as="h2">Get started</Text>

          <Text as="p">Pick a template or start from scratch.</Text>
        </>
      ),
    },
    {
      id: SECTION_IDS.RUNDOWN,
      type: 'disclosure',
      title: 'Rundown',
      completed: rundownSectionCompleted,
      locked: rundownSectionLocked,
      form: (
        <RundownForm
          graphicsPackages={graphicPackagesData?.map(({ id, name, version }) => ({
            label: `${name} (${version})`,
            value: id,
          }))}
          state={state}
          defaultDate={state.start ?? searchParams.get('date')}
          stateUpdated={(state) => updateState(state)}
        />
      ),
      info: (
        <>
          <Text as="h2">Rundown details</Text>

          <Text as="p">Set the rundown details and the start date and time for the event.</Text>
        </>
      ),
    },
    {
      id: SECTION_IDS.GRAPHICS,
      type: 'disclosure',
      title: 'Graphics',
      completed: graphicsSectionCompleted,
      locked: graphicsSectionLocked,
      form: (
        <CanvasGraphicsForm
          title="Graphic"
          canvases={canvasesData}
          state={state.canvases || []}
          graphics={graphicsData.filter(
            (g) =>
              (!g.types || g.types.length == 0 || g.types.find((c) => ['general'].includes(c))) &&
              !g.parentId
          )}
          stateUpdated={(update) => updateState({ canvases: update })}
        />
      ),
      info: (
        <>
          <Text as="h2">Configure your canvas</Text>

          <Text as="p">
            Select your graphics package and assign them to the appropriate canvas.
          </Text>
        </>
      ),
    },
    {
      id: SECTION_IDS.BUNDLE,
      type: 'disclosure',
      title: 'Bundles',
      completed: bundleSectionCompleted,
      locked: bundleSectionLocked,
      form: (
        <CanvasGraphicsForm
          title="Bundle"
          showToggleAll={false}
          canvases={canvasesData}
          state={state.canvases || []}
          stateUpdated={(canvases) => updateState({ canvases })}
          graphics={graphicsData.filter(
            (g) =>
              !g.types ||
              g.types.length == 0 ||
              g.types.find((c) => ['bundle'].includes(c)) ||
              g.parentId
          )}
        />
      ),
      info: <></>,
    },
    {
      id: SECTION_IDS.MATCH_CONTROL,
      type: 'disclosure',
      title: 'Match control',
      completed: matchControlSectionCompleted,
      locked: matchControlSectionLocked,
      form: (
        <div className="divide-y border-elevated-2">
          <RundownMatchControlForm
            categories={matchControlTypesData}
            handleChangeCategory={(sport) => {
              if (sport === '') {
                updateState({ matchControl: null });
                return;
              }
              getMatchControl(sport);
            }}
            matchControlState={state.matchControl}
            matchControlStateUpdated={(matchControl) => updateState({ matchControl })}
            handleSelectImage={(handleSelectImage) => {
              setMediaLibraryState(() => ({ open: true, onClose: handleSelectImage }));
            }}
          />
          {state.matchControl?.sport && (
            <CanvasGraphicsForm
              title="Graphic"
              canvases={canvasesData}
              state={state.canvases || []}
              stateUpdated={(canvases) => updateState({ canvases })}
              graphics={graphicsData.filter((g) => g.types?.find((c) => c === 'matchControl'))}
            />
          )}
        </div>
      ),
      info: <></>,
    },
    {
      id: SECTION_IDS.CANVAS,
      type: 'disclosure',
      title: 'Canvas',
      completed: canvasSectionCompleted,
      locked: canvasSectionLocked,
      form: (
        <CanvasForm
          canvases={canvasesData}
          outputs={outputsData || []}
          state={state.canvases || []}
          canvasBackgrounds={canvasBackgroundsData}
          stateUpdated={(canvases) => updateState({ canvases })}
          disabled={state.isLive}
        />
      ),
      info: (
        <>
          <Text as="h2">Configure your canvas</Text>
          <Text as="p">Connect your canvas(es) to the available outputs.</Text>
          <Text as="p">
            Select a background for the canvas. Graphics will be served over the selected
            background.
          </Text>
          <Text as="p" className="mt-2 !text-danger">
            Please note that once a rundown is live, this setting cannot be adjusted.
          </Text>
        </>
      ),
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      <AccordionForm
        title={accordionFormTitle}
        handleChangeSection={(id) => handleChangeSection(id, sections)}
        handleClickNext={() => handleClickNext(sections)}
        openId={selectedSectionId}
        sections={sections}
      />
      <div className="grid grid-cols-12 gap-x-6">
        <Button
          className={styles.RundownFormSubmitButton}
          disabled={submitting || !canSubmit}
          onClick={() => handleSubmit(state, navigate)}
        >
          <>
            {submitting && <Loader className={styles.Spinner} />} {accordionFormTitle}
          </>
        </Button>
      </div>
      <div
        className={`fixed top-0 right-0 bottom-0 left-0 ${
          mediaLibraryState.open ? 'z-10' : '-z-10'
        }`}
      >
        <SlideOut
          open={mediaLibraryState.open}
          handleClickOutsideDrawer={() => {
            setMediaLibraryState({ open: false, onClose: () => {} });
          }}
          size={30}
        >
          <GraphicMediaLibrary onFileSelect={handleSelectImage} setCanHideLibrary={() => {}} />
        </SlideOut>
      </div>
    </div>
  );
}
