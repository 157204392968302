import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import MediaLibraryPage from 'src/pages/MediaLibrary';
import {
  MEDIA_LIBRARY,
  MediaLibraryStateType,
  REQUEST_CREATE_BULK_FILE_UPLOAD,
  REQUEST_CREATE_DIRECTORY,
  REQUEST_DELETE_DIRECTORY,
  REQUEST_DELETE_FILE,
  REQUEST_GET_DIRECTORIES,
  REQUEST_GET_FILES,
  REQUEST_SEARCH_DIRECTORIES,
  REQUEST_SEARCH_FILES,
  REQUEST_UPDATE_DIRECTORY,
} from 'src/redux/reducers/mediaLibrary';
import { USER } from 'src/redux/reducers/user';
import type { Directory, File, Item, Pagination } from '@southfields-digital/mpxlive-components';
import type { StateType } from 'src/redux/reducers';

export type MediaLibraryContainerProps = {
  count: number;
  createFolderAtPath: (...args: any) => void;
  deleteDirectory: (...args: any) => void;
  deleteFile: (...args: any) => void;
  directories: Directory[];
  files: File[];
  getDataByDirectory: (...args: any) => void;
  isLoading: boolean;
  isLocked?: boolean;
  searchCount: number;
  searchDataByDirectory: (...args: any) => void;
  searchResults: Item[];
  updateDirectoryName: ({
    id,
    name,
    onSuccess,
    onFailure,
  }: {
    id: string;
    name: string;
    onSuccess: () => void;
    onFailure: () => void;
  }) => void;
  uploadFiles: ({
    directoryId,
    fileList,
    callback,
  }: {
    directoryId: string;
    fileList: FileList;
    callback: (uploadedFileCount: number) => void;
  }) => void;
  workspaceId?: string;
};

const mapStateToProps = (state: StateType) => {
  const { directories, files }: MediaLibraryStateType = state[MEDIA_LIBRARY];
  const user = state[USER];

  return {
    count: files.data?.count ?? 0,
    directories: (directories.data?.directories ?? []) as Item[],
    files: (files.data?.files ?? []) as Item[],
    isLoading: directories.isLoading || files.isLoading,
    isLocked: directories?.data?.locked ?? false,
    searchCount: files.searchData?.count ?? 0,
    searchResults: [
      ...(directories?.searchData?.directories ?? []),
      ...(files.searchData?.files ?? []),
    ],
    workspaceId: user?.auth?.workspace?.id,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createFolderAtPath: (payload: {
    path: string;
    insertIntoDirectoryId: string;
    callback: () => void;
  }) => {
    dispatch({ type: REQUEST_CREATE_DIRECTORY, payload });
  },
  deleteDirectory: ({ id, callback }: { id: string; callback: () => void }) =>
    dispatch({
      type: REQUEST_DELETE_DIRECTORY,
      payload: {
        id,
        callback,
      },
    }),
  deleteFile: (id: string) => dispatch({ type: REQUEST_DELETE_FILE, payload: { id } }),
  getDataByDirectory: ({
    id,
    initialPath,
    pagination,
    hideLoader,
  }: {
    id: string;
    initialPath?: Directory[];
    pagination?: Pagination;
    hideLoader?: boolean;
  }) => {
    dispatch({
      type: REQUEST_GET_DIRECTORIES,
      payload: {
        id,
        initialPath,
        hideLoader,
      },
    });
    dispatch({
      type: REQUEST_GET_FILES,
      payload: {
        id,
        pagination,
        hideLoader,
      },
    });
  },
  searchDataByDirectory: ({
    id,
    pagination,
    searchTerm = '',
  }: {
    id: string;
    pagination?: Pagination;
    searchTerm?: string;
  }) => {
    const searchPayload = {
      includeNested: true,
      searchTerm: searchTerm,
    };

    dispatch({
      type: REQUEST_SEARCH_DIRECTORIES,
      payload: {
        id,
        ...searchPayload,
      },
    });
    dispatch({
      type: REQUEST_SEARCH_FILES,
      payload: {
        id,
        pagination,
        ...searchPayload,
      },
    });
  },
  updateDirectoryName: ({
    id,
    name,
    onSuccess,
    onFailure,
  }: {
    id: string;
    name: string;
    onSuccess: () => void;
    onFailure: () => void;
  }) => {
    dispatch({ type: REQUEST_UPDATE_DIRECTORY, payload: { name, id, onSuccess, onFailure } });
  },
  uploadFiles: ({
    directoryId,
    fileList,
    callback,
  }: {
    directoryId: string;
    fileList: FileList;
    callback: (uploadedFileCount: number) => void;
  }) =>
    dispatch({
      type: REQUEST_CREATE_BULK_FILE_UPLOAD,
      payload: {
        directoryId,
        fileList,
        callback,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaLibraryPage);

export const connectToMediaLibraryState = (WrappedComponent: React.FC<any>) =>
  connect(mapStateToProps, mapDispatchToProps)(WrappedComponent);
