import { Location, NavigateFunction } from 'react-router-dom';
import { RundownCollectionStateType } from 'src/redux/reducers/rundown/types';

export type LiveRundownCardsProps = {
  currentlyOpenRundownId?: string;
  location: Location;
  navigate: NavigateFunction;
  rundowns: Rundown[];
};

export type LiveRundownGroup = { title: string; rundowns: Rundown[] };

export type LiveRundownProps = {
  currentlyOpenRundownId?: string;
  getLiveRundowns: () => void;
  liveRundowns: RundownCollectionStateType;
};

export enum LiveRundownFlow {
  TAKE_LIVE_RUNDOWN_OFFLINE = 'take-live-rundown-offline',
}
