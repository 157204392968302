import { MatchControl } from 'src/types/matchControl';
import { Contestant as ContestantType } from 'src/types/matchControl/contestant';
import { Clock } from 'src/types/matchControl/clock';
import { SportCategory } from 'src/types/matchControl/sport';

import Select from 'src/components/Select';
import { FormGroup } from '@southfields-digital/mpxlive-components';
import Soccer from './Soccer';
import Hockey from './Hockey';
import Tennis from './Tennis';
import Volleyball from './Volleyball';

type Props = {
  categories: SportCategory[];
  handleChangeCategory: (value: SportCategory['value'] | '') => void;
  matchControlState: Partial<MatchControl> | null | undefined;
  matchControlStateUpdated: (matchControl: MatchControl) => void;
  handleSelectImage?: (callback: (src: string) => void) => void;
};

const RundownMatchControlForm = ({
  categories,
  handleChangeCategory,
  matchControlState,
  matchControlStateUpdated,
  handleSelectImage,
}: Props) => {
  const selectedSport = matchControlState?.sport || '';
  const options = [{ label: '-', value: '' }, ...categories];

  const handleChangeContestant = (contestant: ContestantType, index: number) => {
    if (!matchControlState || !matchControlState.contestants) return;

    const updatedContestants = matchControlState.contestants.map((c, idx) => {
      return idx === index ? contestant : c;
    });

    matchControlStateUpdated({
      ...matchControlState,
      contestants: updatedContestants,
    } as MatchControl);
  };

  const handleChangeClock = (clock: Clock, index: number) => {
    if (!matchControlState || !('clocks' in matchControlState) || !matchControlState.clocks) return;

    const updatedClocks = matchControlState.clocks.map((c, idx) => {
      return idx === index ? clock : c;
    });

    matchControlStateUpdated({
      ...matchControlState,
      clocks: updatedClocks,
    } as MatchControl);
  };

  const handleClickMedia = (contestant: ContestantType, index: number) => {
    if (!handleSelectImage) return;
    const callback = (imgUrl: string) => {
      handleChangeContestant({ ...contestant, imageUrl: imgUrl }, index);
    };

    handleSelectImage(callback);
  };

  return (
    <div className="flex flex-col divide-y !divide-elevated-2">
      <FormGroup className="p-4" label="Match configuration" htmlFor="matchConfiguration">
        <Select
          id="matchConfiguration"
          options={options}
          onChange={(e) => handleChangeCategory(e.currentTarget.value as SportCategory['value'])}
          value={selectedSport}
        />
      </FormGroup>

      {selectedSport ? (
        <div className="flex flex-col gap-6">
          {(() => {
            switch (selectedSport) {
              case 'soccer':
                return (
                  <Soccer
                    contestants={matchControlState?.contestants || []}
                    clocks={
                      (matchControlState &&
                        'clocks' in matchControlState &&
                        matchControlState?.clocks) ||
                      []
                    }
                    handleChangeContestant={handleChangeContestant}
                    handleChangeClock={handleChangeClock}
                    handleClickMedia={handleClickMedia}
                  />
                );
              case 'hockey':
                return (
                  <Hockey
                    handleChangeContestant={handleChangeContestant}
                    handleChangeClock={handleChangeClock}
                    contestants={matchControlState?.contestants || []}
                    handleClickMedia={handleClickMedia}
                    clocks={
                      (matchControlState &&
                        'clocks' in matchControlState &&
                        matchControlState?.clocks) ||
                      []
                    }
                  />
                );
              case 'tennis':
                return (
                  <Tennis
                    handleChangeContestant={handleChangeContestant}
                    contestants={matchControlState?.contestants || []}
                    handleClickMedia={handleClickMedia}
                  />
                );
              case 'volleyball':
                return (
                  <Volleyball
                    handleChangeContestant={handleChangeContestant}
                    contestants={matchControlState?.contestants || []}
                    handleClickMedia={handleClickMedia}
                  />
                );
              default:
                return null;
            }
          })()}
        </div>
      ) : null}
    </div>
  );
};

export default RundownMatchControlForm;
