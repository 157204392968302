import { useEffect, useState } from 'react';
import { useTimer } from '../hooks';
import { SoccerMatchControl } from 'src/types/matchControl';
import { MainClock } from 'src/types/matchControl/clock';
import { Control } from 'src/types/matchControl/control';
import { SoccerContestant } from 'src/types/matchControl/contestant';
import { ShootOut as ShootOutType } from 'src/types/matchControl/shootOut';
import { CardTypes } from 'src/types/matchControl/card';
import { MatchControlVariant } from '../types';

import {
  TeamColors,
  ClockIn,
  ClockStart,
  ExtraIn,
  Score,
  TimeIn,
  Cards,
  ShootOut,
  ExtendedContestant,
} from '../components';

import styles from '../MatchControl.module.scss';

const VerticalDivider = () => <div className="h-full border-r border-elevated-2" />;

const MAIN_CLOCK_ID = 'clock';
const EXTRA_TIME_ID = 'extraTime';
const SHOOT_OUT_ID = 'shootOut';

type Props = {
  matchControl: SoccerMatchControl;
  matchControlGraphics: Control[];
  handleStartTime: (clock: MainClock) => void;
  handleStopTime: (clock: MainClock) => void;
  handleDispatchControl: (control: Control | undefined) => void;
  handleChangeTime: (clock: MainClock, time: number) => void;
  handleChangeExtraTime: (clock: MainClock, amount: number) => void;
  handleChangeCards: (contestant: SoccerContestant, card: CardTypes, amount: number) => void;
  handleChangeShortName: (contestant: SoccerContestant, name: string) => void;
  handleChangeScore: (contestant: SoccerContestant, amount: number) => void;
  handleChangeColor: (
    contestant: SoccerContestant,
    color: 'primaryColor' | 'secondaryColor',
    value: string
  ) => void;
  disabled?: boolean;
  variant?: MatchControlVariant;
  handleUpdateMatchControl: (matchControl: SoccerMatchControl) => void;
  handleUpdateShootOut: (shootOut: ShootOutType) => void;
};

const Soccer = ({
  matchControl,
  handleChangeCards,
  handleChangeColor,
  handleChangeExtraTime,
  handleChangeScore,
  handleChangeShortName,
  handleChangeTime,
  handleDispatchControl,
  handleStartTime,
  handleStopTime,
  handleUpdateMatchControl,
  handleUpdateShootOut,
  matchControlGraphics,
  variant = 'default',
}: Props) => {
  const { clocks, contestants, shootOut } = matchControl;
  const [mainClock] = clocks;
  const [home, away] = contestants;
  const [shootOutState, setShootOutState] = useState<ShootOutType | undefined>(shootOut);

  useEffect(() => {
    setShootOutState(shootOut);
  }, [matchControl.shootOut]);

  const { time: mainClockTime, setTimer } = useTimer(
    10,
    mainClock.clockTime,
    mainClock.clockCountDirection
  );

  // useEffect to update the timer with offset when the clock changes -> make hook to account for offset
  useEffect(() => {
    if (!mainClock) return;

    const clock = mainClock;
    const serverTimeOffset = clock.serverTimeUpdate
      ? new Date(clock.serverTimeUpdate).getTime() - Date.now()
      : 0;

    const startedAt = clock.clockTimeUpdatedAt
      ? new Date(clock.clockTimeUpdatedAt).getTime() - serverTimeOffset
      : null;

    setTimer(clock.clockTime || 0, startedAt ? new Date(startedAt) : null);
  }, [mainClock, mainClock?.clockTimeUpdatedAt, mainClock?.clockTime]);

  return (
    <div className={styles.MainWrapper}>
      <div className={styles.ItemWrapper}>
        <div className={styles.ExtraInWrapper}>
          <ClockStart
            clockRunning={Boolean(mainClock.clockTimeUpdatedAt)}
            handleStartTimer={() => handleStartTime(mainClock)}
            handleStopTimer={() => handleStopTime(mainClock)}
          />
          <ClockIn
            clockLive={Boolean(
              matchControlGraphics?.find(
                (control) => control.controlId === MAIN_CLOCK_ID && control.live
              )
            )}
            handleToggleGraphic={() =>
              handleDispatchControl(
                matchControlGraphics?.find((c) => c.controlId === MAIN_CLOCK_ID)
              )
            }
          />
        </div>
        <TimeIn
          variant={variant}
          time={mainClockTime ? mainClockTime : 0}
          extraTime={mainClock.extraTime}
          precision="minutes"
          handleChangeTime={(time) => handleChangeTime(mainClock, mainClock.clockTime + time)}
        />
        <ExtraIn
          isRunning={Boolean(
            matchControlGraphics?.find(
              (control) => control.controlId === EXTRA_TIME_ID && control.live
            )
          )}
          handleChangeExtraTime={(amount) =>
            handleChangeExtraTime(mainClock, (mainClock.extraTime ?? 0) + amount)
          }
          handleToggleIsExtraTimeLive={() =>
            handleDispatchControl(matchControlGraphics?.find((c) => c.controlId === EXTRA_TIME_ID))
          }
        />
      </div>

      <div className={styles.Divider} />

      <div className={styles.ItemWrapper}>
        {variant === 'extended' ? (
          <ExtendedContestant
            logoUrl={home.imageUrl}
            shortName={home.shortName}
            handleChangeShortName={(name) => handleChangeShortName(home, name)}
          />
        ) : (
          <Cards
            card={CardTypes.RED_CARD}
            teamName={home.shortName}
            amount={home.redCards || 0}
            handleChangeCards={(card, amount) => handleChangeCards(home, card, amount)}
            handleChangeTeamName={(name) => handleChangeShortName(home, name)}
          />
        )}

        <div className={styles.CurrentScoreWrapper}>
          <div className="flex flex-col gap-6">
            <Score
              variant={variant}
              goals={home.score?.points || 0}
              handleChangeScore={(amount) => handleChangeScore(home, amount)}
            >
              <TeamColors
                primaryColor={home.primaryColor}
                secondaryColor={home.secondaryColor}
                handleChangePrimaryColor={(color) => handleChangeColor(home, 'primaryColor', color)}
                handleChangeSecondaryColor={(color) =>
                  handleChangeColor(home, 'secondaryColor', color)
                }
              />
            </Score>
            {variant === 'extended' && (
              <Cards
                card={CardTypes.RED_CARD}
                variant={variant}
                amount={home.redCards || 0}
                handleChangeCards={(card, amount) => handleChangeCards(home, card, amount)}
              />
            )}
          </div>
          <VerticalDivider />
          <div className="flex flex-col gap-6">
            <Score
              variant={variant}
              inverted
              goals={away.score?.points || 0}
              handleChangeScore={(amount) => handleChangeScore(away, amount)}
            >
              <TeamColors
                primaryColor={away.primaryColor}
                secondaryColor={away.secondaryColor}
                handleChangePrimaryColor={(color) => handleChangeColor(away, 'primaryColor', color)}
                handleChangeSecondaryColor={(color) =>
                  handleChangeColor(away, 'secondaryColor', color)
                }
              />
            </Score>
            {variant === 'extended' && (
              <Cards
                card={CardTypes.RED_CARD}
                variant={variant}
                inverted
                amount={away.redCards || 0}
                handleChangeCards={(card, amount) => handleChangeCards(away, card, amount)}
              />
            )}
          </div>
        </div>

        {variant === 'extended' ? (
          <ExtendedContestant
            logoUrl={away.imageUrl}
            shortName={away.shortName}
            handleChangeShortName={(name) => handleChangeShortName(away, name)}
          />
        ) : (
          <Cards
            card={CardTypes.RED_CARD}
            inverted
            teamName={away.shortName}
            amount={away.redCards || 0}
            handleChangeCards={(card, amount) => handleChangeCards(away, card, amount)}
            handleChangeTeamName={(name) => handleChangeShortName(away, name)}
          />
        )}
      </div>
      <div className={styles.Divider} />
      <div>
        <ShootOut
          hasShootOut={matchControl.hasShootOut}
          handleToggleShootOut={() => {
            const updatedMatchControl = { ...matchControl, hasShootOut: !matchControl.hasShootOut };
            handleUpdateMatchControl(updatedMatchControl);
          }}
          isLive={Boolean(
            matchControlGraphics?.some(
              (control) => control.controlId === SHOOT_OUT_ID && control.live
            )
          )}
          handleClickLive={() =>
            handleDispatchControl(matchControlGraphics?.find((c) => c.controlId === SHOOT_OUT_ID))
          }
          shootOutTaker={shootOutState?.taker || 'home'}
          home={{ shortName: home.shortName, attempts: shootOutState?.home || [] }}
          away={{ shortName: away.shortName, attempts: shootOutState?.away || [] }}
          handleUpdateShootOut={(shootOut) => setShootOutState(shootOut)}
          handleClickUpdate={() => {
            if (!shootOutState) return;
            handleUpdateShootOut(shootOutState);
          }}
        />
      </div>
    </div>
  );
};

export default Soccer;
