import { useMemo } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Icon } from '@southfields-digital/mpxlive-components';
import { Id, Items } from './types';
import { RemoveButton } from './buttons';
import VerticalRepeaterRowItem from './VerticalRepeaterRowItem';

type VerticalRepeaterRowProps = {
  item: Items;
  rowIndex: number;
  excludedIds?: Id[];
  isDisabled?: boolean;
  buttonClassNames?: string;
  handleClickRemove: (index: number) => void;
  handleChangeRepeater: (id: Id, rowIndex: number, rows: Items[]) => void;
  handleChangeValue: (id: Id, rowIndex: number, value: string) => void;
  handleSelectMedia?: (
    properties: ImageProperties,
    handleSelectValue?: (value: string) => void
  ) => void;
};

const VerticalRepeaterRow = ({
  item,
  rowIndex,
  excludedIds,
  isDisabled,
  buttonClassNames,
  handleClickRemove,
  ...props
}: VerticalRepeaterRowProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: `VerticalRepeater-${rowIndex}`,
  });

  const currentItems = useMemo(
    () => Object.values(item).filter(({ id }) => !excludedIds?.includes(id)),
    [item, excludedIds]
  );

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <tr key={rowIndex} ref={setNodeRef} style={style}>
      <td {...listeners} {...attributes} className="text-[#5B5D70]">
        <Icon icon="sort" weight="bold" />
      </td>
      {currentItems.map((currentItem, i) => (
        <VerticalRepeaterRowItem key={i} currentItem={currentItem} rowIndex={rowIndex} {...props} />
      ))}
      {!isDisabled && (
        <td className={buttonClassNames}>
          <RemoveButton onClick={() => handleClickRemove(rowIndex)} />
        </td>
      )}
    </tr>
  );
};

export default VerticalRepeaterRow;
