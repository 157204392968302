import classNames from 'classnames/bind';
import { Id, RepeaterProps, Items, Cell, RepeaterRow } from './types';
import { isRepeater, createNewItemGroup } from './utils';

import VerticalRepeater from './VerticalRepeater';
import HorizontalRepeater from './HorizontalRepeater';

import styles from './Repeater.module.scss';

function Repeater({
  id,
  items,
  onChangeRows,
  direction = 'vertical',
  parentRowIndex,
  excludedIds,
  isDisabled,
  handleSelectMedia,
}: RepeaterProps) {
  if (!items.length) return null;
  const isNestedRepeater = parentRowIndex !== undefined;
  const hasRepeater = Object.values(items[0]).some((item) => isRepeater(item));
  const calcDirection =
    direction === 'vertical' && !isNestedRepeater && hasRepeater ? 'horizontal' : direction;

  const cx = classNames.bind(styles);
  const ButtonStyles = cx(styles.ButtonColumn, { [styles.Border]: hasRepeater });

  const updateParent = (rows: Items[]) => {
    try {
      onChangeRows(id, parentRowIndex || 0, structuredClone(rows));
    } catch {}
  };

  const handleClickRemoveRowGroup = (index: number) => {
    if (items.length === 1) return;
    updateParent(items.filter((_, i) => i !== index));
  };

  const handleClickAddRowGroup = () => {
    updateParent([...items, createNewItemGroup(items[0])]);
  };

  const handleChangeRepeater = (id: Id, rowIndex: number, rows: Items[]) => {
    const currentItem = items[rowIndex][id];
    if (!isRepeater(currentItem)) return;

    (items[rowIndex][id] as RepeaterRow).items = rows;
    updateParent(items);
  };

  const handleChangeSort = (newItems: Items[]) => updateParent(newItems);

  const handleChangeValue = (id: Id, rowIndex: number, value: string) => {
    const currentItem = items[rowIndex][id];
    if (isRepeater(currentItem)) return;

    (items[rowIndex][id] as Cell).value = value;
    updateParent(items);
  };

  return (
    <>
      {calcDirection === 'vertical' ? (
        <VerticalRepeater
          items={items}
          handleChangeRepeater={handleChangeRepeater}
          handleChangeValue={handleChangeValue}
          handleClickAdd={handleClickAddRowGroup}
          handleClickRemove={handleClickRemoveRowGroup}
          handleChangeSort={handleChangeSort}
          handleSelectMedia={handleSelectMedia}
          buttonClassNames={ButtonStyles}
          excludedIds={excludedIds}
          isDisabled={isDisabled}
        />
      ) : (
        <HorizontalRepeater
          items={items}
          handleChangeRepeater={handleChangeRepeater}
          handleChangeValue={handleChangeValue}
          handleClickAdd={handleClickAddRowGroup}
          handleClickRemove={handleClickRemoveRowGroup}
          handleChangeSort={handleChangeSort}
          handleSelectMedia={handleSelectMedia}
          buttonClassNames={ButtonStyles}
          excludedIds={excludedIds}
          isDisabled={isDisabled}
        />
      )}
    </>
  );
}

export default Repeater;
