import { useLocation, useNavigate } from 'react-router-dom';
import { Dialog, Button } from '@southfields-digital/mpxlive-components';

import styles from '../GoLive.module.scss';
import { LiveRundownFlow } from 'src/components/LiveRundowns';
import classNames from 'classnames';

type Props = {
  isOpen: boolean;
  isLive: boolean;
  isDisabled?: boolean;
  isTakeOver?: boolean;
  description?: React.ReactNode;
  handleCloseDialog: () => void;
  handleToggleLive: () => void;
};

export default function GoLiveDialog({
  isOpen,
  isLive,
  isDisabled,
  isTakeOver,
  description,
  handleCloseDialog,
  handleToggleLive,
}: Props) {
  const location = useLocation();
  const navigate = useNavigate();

  const navigateAndResetHistoryState = (
    callback: () => void,
    targetPath?: string,
    replace: boolean = true
  ) => {
    callback();

    navigate(targetPath || location.pathname, {
      replace,
      state: undefined,
    });
  };

  const shouldRedirectToReturnPath = Boolean(
    !isDisabled &&
      isLive &&
      location?.state?.flowType === LiveRundownFlow.TAKE_LIVE_RUNDOWN_OFFLINE &&
      location?.state?.returnPath
  );

  return (
    <Dialog open={isOpen} onClose={handleCloseDialog} backdrop>
      <Dialog.Panel className={styles.ContainerDialog}>
        <Dialog.Title>{isLive ? 'Go Offline' : 'Go Live'}</Dialog.Title>

        {isLive && (
          <div className={styles.Warning}>
            <div className={styles.WarningText}>
              This will stop the live output and clear the output canvas.
            </div>
          </div>
        )}

        {description}

        <div className={styles.Buttons}>
          <Button
            variant="secondary"
            onClick={() => navigateAndResetHistoryState(handleCloseDialog)}
          >
            Cancel
          </Button>

          {!isDisabled && (
            <Button
              className={classNames([styles.DangerTertiary])}
              variant={'danger'}
              onClick={() => {
                if (shouldRedirectToReturnPath) {
                  navigateAndResetHistoryState(handleToggleLive, location.state.returnPath, false);
                } else {
                  handleToggleLive();
                }
              }}
            >
              {isLive ? 'Offline' : isTakeOver ? 'Take Over' : 'Go Live'}
            </Button>
          )}
        </div>
      </Dialog.Panel>
    </Dialog>
  );
}
